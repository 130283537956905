import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RoutingDependencies } from './core/constant/routing-dependencies';
import { ScrollToTopComponent } from './core/scroll-to-top/scroll-to-top.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NavigationEnd, Router } from '@angular/router';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RoutingDependencies, ScrollToTopComponent, NgxSpinnerModule, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'emedutech-web';

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0); 
      }
    });
  }
}
