<div class="row main-container">
 <div class="col-12 p-0">
  <router-outlet></router-outlet>
  <app-scroll-to-top></app-scroll-to-top>
 </div>
</div>

<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
[fullScreen]="true"
>
<div class="custom-spinner"></div>
</ngx-spinner>

