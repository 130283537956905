import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '', 
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home', 
        loadComponent: () => import('./core/home/home.component').then(m => m.HomeComponent), 
        data: { title: 'Landing Page' }
    },
    {
        path: 'courses', 
        loadComponent: () => import('./header-menu/course-content-list/component/course-content-list.component').then(m => m.CourseContentListComponent), 
        data: { title: 'Courses' }
    },
    {
        path: 'course-detail/:routeUrl', 
        loadComponent: () => import('./header-menu/course-detail/course-detail.component').then(m => m.CourseDetailComponent), 
        data: { title: 'Course Details' }
    },
    {
        path: 'about-us', 
        loadComponent: () => import('./header-menu/about-us/component/about-us.component').then(m => m.AboutUsComponent), 
        data: { title: 'About Us' }
    },
    {
        path: 'contact-us', 
        loadComponent: () => import('./header-menu/contact-us/contact-us.component').then(m => m.ContactUsComponent), 
        data: { title: 'Contact Us' }
    },

    {
        path: 'contact-us/:routeUrl', 
        loadComponent: () => import('./header-menu/contact-us/contact-us.component').then(m => m.ContactUsComponent), 
        data: { title: 'Contact Us' }
    }
]
